<template>
    <div class="SystemInfo full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-5 flex-grow-1 bg-white">
                <v-row class="d-flex">
                    <v-col cols="6" class="pa-0 pr-4">
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.freeSpaceDevice') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ systeminfo.freeSpaceInternal }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.freeSpaceSD') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ systeminfo.freeSpaceExtension }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('products.products') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ systeminfo.productCount }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('methods.methods') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ systeminfo.methodCount }}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-0 pl-4">
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.serialNumber') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ systeminfo.serialNumber }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.firmwareVersion') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ systeminfo.firmwareVersion }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('system.hardwareVersion') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ systeminfo.hardwareVersion }}</p>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
                <v-row class="d-flex mt-6">
                    <v-col cols="6" class="station pa-0 pr-4 pb-4 d-flex flex-column">
                        <div class="title-bar d-flex ma-3 mb-0 pa-4 align-center flex-grow-0">
                            <p>{{ $t('general.station') }} 1</p>
                        </div>
                        <div class="stationInfo ma-3 mt-0 flex-grow-1">
                            <v-row class="d-flex pa-4">
                                <v-col cols="12" class="pa-0" v-if="systeminfo.stationVersions[1]">
                                    <v-row class="d-flex align-center">
                                        <v-col class="col-4 pa-0">
                                            <p>{{ $t('system.serialNumber') }}</p>
                                        </v-col>
                                        <v-col class="col-8">
                                            <p class="readOnly">{{ systeminfo.stationVersions[1].serialNumber }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" class="pa-0" v-if="systeminfo.stationVersions[1]">
                                    <v-row class="d-flex align-center">
                                        <v-col class="col-4 pa-0">
                                            <p>{{ $t('system.firmwareVersion') }}</p>
                                        </v-col>
                                        <v-col class="col-8">
                                            <p class="readOnly">{{ systeminfo.stationVersions[1].firmwareVersion }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" class="pa-0 pt-6" v-else >
                                    <v-row class="d-flex align-center ">
                                        <v-col class="col-12 pa-0">
                                            <p>{{ $t('system.notConnected') }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="6" class="station pa-0 pl-4 pb-4 d-flex flex-column">
                        <div class="title-bar d-flex ma-3 mb-0 pa-4 align-center flex-grow-0">
                            <p>{{ $t('general.station') }} 2</p>
                        </div>
                        <div class="stationInfo ma-3 mt-0 flex-grow-1">
                            <v-row class="d-flex pa-4">
                                <v-col cols="12" class="pa-0" v-if="systeminfo.stationVersions[2]">
                                    <v-row class="d-flex align-center">
                                        <v-col class="col-4 pa-0">
                                            <p>{{ $t('system.serialNumber') }}</p>
                                        </v-col>
                                        <v-col class="col-8">
                                            <p class="readOnly">{{ systeminfo.stationVersions[2].serialNumber }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" class="pa-0" v-if="systeminfo.stationVersions[2]">
                                    <v-row class="d-flex align-center">
                                        <v-col class="col-4 pa-0">
                                            <p>{{ $t('system.firmwareVersion') }}</p>
                                        </v-col>
                                        <v-col class="col-8">
                                            <p class="readOnly">{{ systeminfo.stationVersions[2].firmwareVersion }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" class="pa-0 pt-6" v-else >
                                    <v-row class="d-flex align-center ">
                                        <v-col class="col-12 pa-0">
                                            <p>{{ $t('system.notConnected') }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="6" class="station pa-0 pr-4 pb-4 d-flex flex-column">
                        <div class="title-bar d-flex ma-3 mb-0 pa-4 align-center flex-grow-0">
                            <p>{{ $t('general.station') }} 3</p>
                        </div>
                        <div class="stationInfo ma-3 mt-0 flex-grow-1">
                            <v-row class="d-flex pa-4">
                                <v-col cols="12" class="pa-0" v-if="systeminfo.stationVersions[3]">
                                    <v-row class="d-flex align-center">
                                        <v-col class="col-4 pa-0">
                                            <p>{{ $t('system.serialNumber') }}</p>
                                        </v-col>
                                        <v-col class="col-8">
                                            <p class="readOnly">{{ systeminfo.stationVersions[3].serialNumber }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" class="pa-0" v-if="systeminfo.stationVersions[3]">
                                    <v-row class="d-flex align-center">
                                        <v-col class="col-4 pa-0">
                                            <p>{{ $t('system.firmwareVersion') }}</p>
                                        </v-col>
                                        <v-col class="col-8">
                                            <p class="readOnly">{{ systeminfo.stationVersions[3].firmwareVersion }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" class="pa-0 pt-6" v-else >
                                    <v-row class="d-flex align-center ">
                                        <v-col class="col-12 pa-0">
                                            <p>{{ $t('system.notConnected') }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="6" class="station pa-0 pl-4 pb-4 d-flex flex-column">
                        <div class="title-bar d-flex ma-3 mb-0 pa-4 align-center flex-grow-0">
                            <p>{{ $t('general.station') }} 4</p>
                        </div>
                        <div class="stationInfo ma-3 mt-0 flex-grow-1">
                            <v-row class="d-flex pa-4">
                                <v-col cols="12" class="pa-0" v-if="systeminfo.stationVersions[4]">
                                    <v-row class="d-flex align-center">
                                        <v-col class="col-4 pa-0">
                                            <p>{{ $t('system.serialNumber') }}</p>
                                        </v-col>
                                        <v-col class="col-8">
                                            <p class="readOnly">{{ systeminfo.stationVersions[4].serialNumber }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" class="pa-0" v-if="systeminfo.stationVersions[4]">
                                    <v-row class="d-flex align-center">
                                        <v-col class="col-4 pa-0">
                                            <p>{{ $t('system.firmwareVersion') }}</p>
                                        </v-col>
                                        <v-col class="col-8">
                                            <p class="readOnly">{{ systeminfo.stationVersions[4].firmwareVersion }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" class="pa-0 pt-6" v-else >
                                    <v-row class="d-flex align-center ">
                                        <v-col class="col-12 pa-0">
                                            <p>{{ $t('system.notConnected') }}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToSystem"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";

export default {
    name: 'SystemInfo',
    components: {
        FooterButton,
    },
    props: {
    },
    data () {
        return{
        }
    },
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/device/systeminfos', 'systeminfo']);
    },
    computed: {
        ...mapState([
            'systeminfo',
        ]),
    },
    methods:{
        goToSystem(){
            this.$router.push('system');
        },
    }
}
</script>
<style scoped lang="scss">
.title{
    min-height: 76px;
    display: flex;
    align-items: center;

}
h2{
    font-size: 1rem;
    margin-top: 36px;
}
.border-bottom{
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    max-width: calc(100% - 24px);
}
.title-bar {
    background-color: $disiDarkblueMedium;
    height: 60px;

    p {
        color: white;
        font-weight: 500;
    }
}
.stationInfo{
    background-color: $disiBluegreyLight;
}
</style>